export function formatNumber(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Date & time 
 */
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);

export function formatDateTime(dateTime: string) {
    const now = dayjs();
    const date = dayjs(dateTime ?? now);
    return date.isAfter(now.subtract(1, 'day')) ? date.format('h:mm A') : date.format('DD/MM/YYYY');
}

export function relativeDate(date: string) {
  const messageDate = dayjs(date);
  
  return messageDate.calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd',
    sameElse: 'D MMM YYYY'
  });
}
